<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <div class="text-center">
          <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('globalTrans.registration_information') }}</h5>
        </div>
      </template>
      <template v-slot:searchHeaderAction>
        <!-- <router-link :class="'btn btn-success text-light'" :to="{ path: 'registration'}" size="sm">
          {{ $t('elearning_iabm.registration') }} {{ $t('globalTrans.list') }}
        </router-link> -->
        <router-link  class="btn-add" to="registration">{{ $t('elearning_iabm.registration') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
        <b-overlay :show="isLoading">
          <b-container class="bv-example-row pl-4 pr-4">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form id="form" @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">
                    {{$t('globalTrans.personal_information')}}
                  </h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col sm="4">
                      <ValidationProvider name="Name" vid='name' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="name"
                              v-model="formData.name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="name_bn"
                              v-model="formData.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Photo" vid="image" :rules="formData.id ? '' : 'required'">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('globalTrans.photo')"
                          label-for="image"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('globalTrans.photo')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-file
                              id="image"
                              accept="image/*"
                              v-model="formData.image_file"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Signature" vid="signature" :rules="formData.id ? '' : 'required'">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('globalTrans.signature')"
                          label-for="signature"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('globalTrans.signature')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-file
                              id="signature"
                              accept="image/*"
                              v-model="formData.signature_file"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="NID" vid='nid' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.nid')}}
                          </template>
                          <b-form-input
                              id="nid"
                              type="number"
                              v-model="formData.nid"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="date_of_birth"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.dob')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            class="fromDate"
                            v-model="formData.date_of_birth"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Mobile" vid='mobile' rules="required|min:11|max:11">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="mobile"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            id="mobile"
                            v-model="formData.mobile"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Email" vid='email' rules="required|email|min:3">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="email"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.email')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="email"
                            v-model="formData.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="gender"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.gender')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.gender"
                            :options="genderList"
                            id="gender"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Marital Status" vid="marital_status" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="marital_status"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.mat_status')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="formData.marital_status"
                              :options="maritalList"
                              id="marital_status"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Blood Group" vid="blood_group" rules="">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="blood_group"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.blood_group')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.blood_group"
                            :options="bloodGroupList"
                            id="blood_group"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Religion" vid="religion" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="religion"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalTraining.religion')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="formData.religion"
                              :options="religionList"
                              id="religion"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <ValidationProvider name="Emergency Contact" vid='emergency_contact' rules="required|min:11|max:11">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="emergency_contact"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_iabm.emergency_contact')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="emergency_contact"
                              v-model="formData.emergency_contact"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </template>
              </body-card>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('externalTraining.professional_info')}}</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col sm="4">
                        <ValidationProvider name="Organization Type" vid="org_type_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="org_type_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_config.organization_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-radio-group
                                :disabled="$route.query.id ? true : false"
                                id="org_type_id"
                                style="margin-top: 6px"
                                v-model="formData.professional_info.org_type_id"
                                :options="orgTypeList"
                                name="org_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-radio-group>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 3">
                      <ValidationProvider name="Organization Name (En)" vid="org_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="org_name_en"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="org_name_en"
                            v-model="formData.professional_info.org_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 3">
                      <ValidationProvider name="Organization Name (Bn)" vid="org_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="org_name_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="org_name_bn"
                            v-model="formData.professional_info.org_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 1">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.professional_info.org_id"
                            :options="nominatedMocList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 2">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.professional_info.org_id"
                            :options="nominatedPrivateList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 1">
                        <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.professional_info.office_type_id"
                                    :options="officeTypeList"
                                    id="office_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 1">
                        <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.professional_info.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 1">
                        <ValidationProvider name="Job Type" vid="job_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="job_type_id"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{ $t('elearning_iabm.job_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.professional_info.job_type_id"
                                :options="jobTypeList"
                                id="job_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4" v-if="formData.professional_info.org_type_id === 1">
                        <ValidationProvider name="Batch" vid="batch" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="batch"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('elearning_iabm.batch')}}
                                </template>
                                <b-form-input
                                    id="batch"
                                    v-model="formData.professional_info.batch"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Designation Name (En)" vid="designation_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="designation_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.designation')}} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="designation_en"
                                    v-model="formData.professional_info.designation_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Designation Name (Bn)" vid="designation_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="designation_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.designation')}} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="designation_bn"
                                    v-model="formData.professional_info.designation_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Service Id" vid="service_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.service_id')}}
                                </template>
                                <b-form-input
                                    id="service_id"
                                    type="number"
                                    v-model="formData.professional_info.service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col v-if="formData.professional_info.org_type_id === 1" sm="4">
                        <ValidationProvider name="Pay Grade" vid="pay_grade" :rules="formData.professional_info.org_type_id === 1 ? 'required' : ''">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="pay_grade"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.pay_grade')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.professional_info.grade_id"
                                    :options="gradeList"
                                    id="pay_grade"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Controlling Authority (Designation)" vid="controlling_authority">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="controlling_authority"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('externalTraining.designation_c')}}
                            </template>
                            <b-form-input
                                id="controlling_authority"
                                v-model="formData.professional_info.controlling_authority"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Telephone" vid='telephone_c' rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="telephone_c"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.telephone_c')}}
                                </template>
                                <b-form-input
                                    type="number"
                                    id="telephone_c"
                                    v-model="formData.professional_info.telephone"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                    <ValidationProvider name="Email (Controlling Authority)" vid='email_c' rules="email">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="email_c"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('externalTraining.email_c')}}
                            </template>
                            <b-form-input
                                id="email_c"
                                v-model="formData.professional_info.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Office Email" vid='office_mail' rules="email">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="office_mail"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.office_mail')}}
                                </template>
                                <b-form-input
                                    id="nid"
                                    v-model="formData.professional_info.office_mail_address"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                </template>
              </body-card>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('externalTraining.academic_info')}}</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col sm="4">
                        <ValidationProvider name="Degree" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="highest_degree"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.highest_degree')}}
                                </template>
                                <b-form-input
                                    id="name_of_highest_degree"
                                    v-model="formData.name_of_highest_degree"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="University/Board" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="board_university"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalTraining.board_university')}}
                                </template>
                                <b-form-input
                                    id="board"
                                    v-model="formData.board"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                </template>
              </body-card>
              <body-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{$t('externalTraining.present_address')}}</h4>
                </template>
                <template v-slot:body>
                    <b-row class="mb-3">
                        <b-col sm="4">
                            <ValidationProvider name="Area Type" vid="pre_area_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="area_type_id "
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_area_type_id "
                                    id="area_type_id"
                                    :options="getAreaTypeList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow">
                            <ValidationProvider name="Division" vid="pre_division_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="pre_division_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.division')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_division_id"
                                    :options="divisionList"
                                    id="pre_division_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow">
                            <ValidationProvider name="District" vid="pre_district_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="pre_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_district_id"
                                    :options="districtList"
                                    id="pre_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="PauroshobaItemShow || UnionItemShow">
                            <ValidationProvider name="Upazila" vid="upazila_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="upazila_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_upazilla_id"
                                    :options="upazilaList"
                                    id="upazila_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="UnionItemShow">
                            <ValidationProvider name="Union" vid="union_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.union')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_union_id"
                                    :options="unionList"
                                    id="union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="CityCorpItemShow">
                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="city_corporation_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.city_corporation') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_city_corporation_id"
                                    :options="cityCorporationList"
                                    id="city_corporation_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="PauroshobaItemShow">
                            <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="pauroshoba_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.pouroshova') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_pauroshoba_id"
                                    :options="pauroshobaList"
                                    id="pauroshoba_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow">
                            <ValidationProvider name="Ward" vid="ward_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="ward_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.ward') }}
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.pre_ward_id"
                                    :options="wardList"
                                    id="ward_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h5 class="ex_form_title">
                                <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="formData.address.same_as_pre_address"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                                    class="checkboxes"
                                >
                                    <p class="mb-0" style="color: #3f414d;font-size: 16px;font-weight: 600;">{{ $t('externalTraining.parmanent_address') }} {{ '(' + $t('externalTraining.same_as') + ')' }}</p>
                                </b-form-checkbox>
                            </h5>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col sm="4">
                            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="area_type_id "
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_area_type_id "
                                    id="area_type_id"
                                    :options="getAreaTypeList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow1">
                            <ValidationProvider name="Division" vid="division_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="division_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.division')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_division_id"
                                    :options="divisionList"
                                    id="division_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow1">
                            <ValidationProvider name="District" vid="district_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="per_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_district_id"
                                    :options="districtList1"
                                    id="per_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="PauroshobaItemShow1 || UnionItemShow1">
                            <ValidationProvider name="Upazila" vid="upazila_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="upazila_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_upazilla_id"
                                    :options="upazilaList1"
                                    id="upazila_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="UnionItemShow1">
                            <ValidationProvider name="Union" vid="union_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.union')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_union_id"
                                    :options="unionList1"
                                    id="union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="CityCorpItemShow1">
                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="city_corporation_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.city_corporation') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_city_corporation_id"
                                    :options="cityCorporationList1"
                                    id="city_corporation_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="PauroshobaItemShow1">
                            <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="pauroshoba_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.pouroshova') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_pauroshoba_id"
                                    :options="pauroshobaList1"
                                    id="pauroshoba_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-show="ItemShow1">
                            <ValidationProvider name="Ward" vid="ward_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="ward_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.ward') }}
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="formData.address.per_ward_id"
                                    :options="wardList1"
                                    id="ward_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="formData.address.same_as_pre_address === '1'"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="success" class="mr-2 btn-sm" :disabled="isLoading">
                    <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                    <span v-else>{{ saveBtnName }}</span>
                  </b-button>
                  <router-link to="registration" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                </div>
              </div>
            <!-- <div class="row col-12">
                <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                  <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                  <span v-else>{{ saveBtnName }}</span>
                </b-button>
            </div> -->
            </b-form>
          </ValidationObserver>
          </b-container>
        </b-overlay>
      </template>
    </card>
  </div>
</template>
<style>
.checkboxes label.custom-control-label {
    color: white !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
</style>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { personalInfoStore, personalInfoUpdate, personalInfoShow } from '../../api/routes'
// import { Common } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { SpinnerPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(SpinnerPlugin)

export default {
  // mixin: [Common],
  created () {
    if (this.id) {
      this.getFormData()
    }
  },
  data () {
    return {
      id: this.$route.query.id,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      ItemShow: false,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      ItemShow1: false,
      CityCorpItemShow1: false,
      PauroshobaItemShow1: false,
      UnionItemShow1: false,
      formData: {
        name: '',
        name_bn: '',
        date_of_birth: '',
        email: '',
        nid: '',
        mobile: '',
        gender: '',
        marital_status: '',
        blood_group: '',
        religion: '',
        emergency_contact: '',
        name_of_highest_degree: '',
        board: '',
        image_file: [],
        signature_file: [],
        professional_info: {
          org_name_en: '',
          org_name_bn: '',
          org_type_id: 0,
          org_id: 0,
          job_type_id: 0,
          designation_en: '',
          designation_bn: '',
          office_type_id: 0,
          office_id: 0,
          grade_id: 0,
          service_id: '',
          batch: '',
          controlling_authority: '',
          telephone: '',
          email: '',
          office_mail_address: ''
        },
        address: {
          pre_area_type_id: 0,
          pre_division_id: 0,
          pre_district_id: 0,
          pre_upazilla_id: 0,
          pre_ward_id: '',
          pre_union_id: '',
          pre_city_corporation_id: '',
          pre_pauroshoba_id: '',
          same_as_pre_address: '',
          per_area_type_id: 0,
          per_division_id: 0,
          per_district_id: 0,
          per_upazilla_id: 0,
          per_city_corporation_id: '',
          per_pauroshoba_id: '',
          per_ward_id: '',
          per_union_id: ''
        },
        is_draft: 1,
        status: 1
      },
      saved: false,
      officeTypeList: [],
      officeList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      wardList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      districtList1: [],
      upazilaList1: [],
      unionList1: [],
      wardList1: [],
      cityCorporationList1: [],
      pauroshobaList1: [],
      designationList: [],
      zoneList: [],
      unitList: [],
      orgType: [
        {
          value: 1,
          text: 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        },
        {
          value: 3,
          text: 'Others',
          text_en: 'Others',
          text_bn: 'অন্যান্য'
        }
      ],
      loading: false,
      isDisabled: false,
      isLoading: false
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    orgTypeList: function () {
      return this.orgType.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    jobTypeList () {
      return this.$store.state.TrainingElearning.commonObj.jobTypeList
    },
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.areaTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    genderList () {
      const genderList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
      ]
      return genderList
    },
    maritalList () {
      const status = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত' }
      ]
      return status
    },
    bloodGroupList () {
      const bloodGroupList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'A-' : 'এ-' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'B-' : 'বি-' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'O-' : 'ও-' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' },
        { value: 8, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি-' }
      ]
      return bloodGroupList
    },
    religionList () {
      const religionList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
      ]
      return religionList
    },
    nominatedMocList () {
      return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 1)
    },
    nominatedPrivateList () {
      return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 2)
    },
    gradeList: function () {
      return this.$store.state.commonObj.gradeList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text }
        }
      })
    }
  },
  watch: {
    'formData.address.pre_area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAreaTypeData(this.formData.address.pre_area_type_id)
      }
    },
    'formData.address.per_area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAreaTypeData1(this.formData.address.per_area_type_id)
      }
    },
    'formData.address.pre_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData.address.pre_district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData.address.pre_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'formData.address.pre_city_corporation_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'formData.address.pre_pauroshoba_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'formData.address.pre_union_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByUnion(newVal)
    },
    'formData.address.per_division_id': function (newVal, oldVal) {
      this.districtList1 = this.getDistrictList(newVal)
    },
    'formData.address.per_district_id': function (newVal, oldVal) {
      this.cityCorporationList1 = this.getCityCorporationList(newVal)
      this.upazilaList1 = this.getUpazilaList(newVal)
    },
    'formData.address.per_upazilla_id': function (newVal, oldVal) {
      this.unionList1 = this.getUnionList(newVal)
      this.pauroshobaList1 = this.getPauroshobaList(newVal)
    },
    'formData.address.per_city_corporation_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByCityCorportaion(newVal)
    },
    'formData.address.per_pauroshoba_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByPauroshoba(newVal)
    },
    'formData.address.per_union_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByUnion(newVal)
    },
    'formData.professional_info.org_id': function (newVal, oldVal) {
      const orgData = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === newVal)
      this.designationList = this.getDesignationByOrg(orgData.org_id)
      this.officeTypeList = this.getOfficeTypeList(orgData.org_id)
    },
    'formData.professional_info.office_type_id': function (newValue) {
      this.officeList = this.getParentOfficeList(newValue)
    },
    'formData.address.same_as_pre_address': function (newVal, oldVal) {
      if (newVal === '1') {
        this.formData.address.per_area_type_id = this.formData.address.pre_area_type_id
        this.formData.address.per_division_id = this.formData.address.pre_division_id
        this.formData.address.per_district_id = this.formData.address.pre_district_id
        this.formData.address.per_upazilla_id = this.formData.address.pre_upazilla_id
        this.formData.address.per_city_corporation_id = this.formData.address.pre_city_corporation_id
        this.formData.address.per_pauroshoba_id = this.formData.address.pre_pauroshoba_id
        this.formData.address.per_ward_id = this.formData.address.pre_ward_id
        this.formData.address.per_union_id = this.formData.address.pre_union_id
        this.getAreaTypeData1(this.formData.address.pre_area_type_id)
      }
    }

  },
  methods: {
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.CommonService.commonObj.designationList
      if (desigList) {
        return desigList.filter(item => item.org_id === parseInt(orgId))
      }
      return desigList
    },
    getAreaTypeData (typeId) {
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    getAreaTypeData1 (typeId) {
      this.ItemShow1 = true
      if (typeId === 1) {
        this.CityCorpItemShow1 = true
        this.PauroshobaItemShow1 = false
        this.UnionItemShow1 = false
      } else if (typeId === 2) {
        this.CityCorpItemShow1 = false
        this.PauroshobaItemShow1 = true
        this.UnionItemShow1 = false
      } else if (typeId === 3) {
        this.CityCorpItemShow1 = false
        this.PauroshobaItemShow1 = false
        this.UnionItemShow1 = true
      }
    },
    async getFormData () {
      this.isLoading = true
      const params = { id: this.id }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalInfoShow, params)
      this.formData = JSON.parse(JSON.stringify(result.data))
      if (this.formData.address.pre_area_type_id) {
        this.getAreaTypeData(this.formData.address.pre_area_type_id)
      }
      if (this.formData.address.per_area_type_id) {
        this.getAreaTypeData1(this.formData.address.per_area_type_id)
      }
      if (this.formData.address.same_as_pre_address) {
        this.formData.address.same_as_pre_address = String(this.formData.address.same_as_pre_address)
      }
      this.isLoading = false
    },
    async register () {
      this.isLoading = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: true, listReload: false }
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
          if (key === 'image_file') {
              formData.append('image', this.formData.image_file)
          } else if (key === 'signature_file') {
              formData.append('signature', this.formData.signature_file)
          } else {
              formData.append(key, this.formData[key])
          }
      })
      formData.append('address', JSON.stringify(this.formData.address))
      formData.append('professional_info', JSON.stringify(this.formData.professional_info))
      if (!this.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${personalInfoStore}`, formData, config)
      } else {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${personalInfoUpdate}/${this.id}`, formData, config)
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/iabm/registration')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
      this.isLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
      if (upazilaId) {
        return unionList.filter(union => union.upazila_id === upazilaId)
      }

      return unionList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getParentOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === parseInt(upazillaId))
      if (upazillaId) {
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      }
    },
    getWardListByCityCorportaion (cityCorpId) {
      const objectData = this.$store.state.CommonService.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 1)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByPauroshoba (pauroshobaId) {
      const objectData = this.$store.state.CommonService.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 1)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByUnion (unionId) {
      const objectData = this.$store.state.CommonService.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 1)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getUnitList (zoneId = null) {
      const unitList = this.$store.state.agriMarketing.unitList
      if (unitList) {
        return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
      }
      return unitList
    }
  }
}
</script>
